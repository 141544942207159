import React, { Component } from "react";
import config from "./config";
import Login from "./login/Login";
import MainContribuitors from "./contributors/MainContribuitors";
import logo from "./SIGLA.png";
import env from "./env";
import Swal from "sweetalert2";

export default class MainPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            displayingPage: config.DisplayingPage.login,
            currentOperator: {},
        };

        this.onLogin = this.onLogin.bind(this);
        this.logout = this.logout.bind(this);
    }

    onLogin(currentOperator) {
        if (currentOperator) {
            let url = env.ApiBaseUrl + 'configs/all';
            fetch(url, {
                method: 'GET',
                headers: new Headers({
                    'cache-control': "no-cache"
                })
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            ...this.state,
                            appConfigs: result,
                            currentOperator: currentOperator,
                            displayingPage: config.DisplayingPage.view,
                        });
                    },
                    () => {
                        Swal.fire({
                            title: 'Eroare',
                            text: "Vă rugăm să încercați din nou ",
                            icon: 'error',
                        });
                    }
                );
        }
    };

    logout() {
        this.setState({
            ...this.state,
            currentOperator: {},
            displayingPage: config.DisplayingPage.login,
        })
    };

    render() {
        let { displayingPage, currentOperator, appConfigs } = this.state;
        return (
            <div className="main-div">
                <img src={logo} className="logo" alt="logo" />
                {displayingPage === config.DisplayingPage.login && <Login onLogin={this.onLogin} />}
                {displayingPage === config.DisplayingPage.view &&
                    <MainContribuitors
                        logout={this.logout}
                        appConfigs={appConfigs}
                        currentOperator={currentOperator}
                    />}
            </div>
        );
    };
}