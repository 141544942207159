import React, { Component } from "react";
import { Button } from '@mui/material';
import ContribuitorsManager from "./ContribuitorsManager";
import ContribuitorsViewer from "./ContribuitorsViewer";
import ContribuitorsLists from "./ContribuitorsLists";
import env from "../env";
import config from "../config";
import ContribuitorsSituations from "./ContribuitorsSituations";
import MainOperators from "../operators/MainOperators";

export default class MainContribuitors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingState: config.LoadingStates.loading,
            displayingPage: config.DisplayingPage.view,
            usedIds: [],
            editedContribuitor: {}
        };

        this.showPage = this.showPage.bind(this);
        this.getUsedIds = this.getUsedIds.bind(this);
    }

    getUsedIds() {
        let url = env.ApiBaseUrl + "users/id";
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'cache-control': "no-cache"
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.loaded,
                        usedIds: result,
                    })
                },
                (error) => {
                    console.log("Eroare: ", error);
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.error,
                    })
                }
            );
    };

    componentDidMount() {
        this.getUsedIds();
    };

    showPage(displayingPage, contribuitor) {
        this.setState({
            ...this.state,
            displayingPage: displayingPage,
            editContributer: contribuitor,
        })
    }

    render() {
        let { displayingPage, loadingState } = this.state;
        let { currentOperator, appConfigs } = this.props;
        return (
            <div>
                {loadingState === config.LoadingStates.error &&
                    <div>
                        Eroare: Aplicația nu poate porni
                        <br />
                        <Button className="c-button" variant="outlined" color='warning' onClick={this.props.logout}>Autentificați-vă din nou</Button>
                    </div>}
                {loadingState === config.LoadingStates.loading &&
                    <div>Se încarcă datele...</div>}
                {loadingState === config.LoadingStates.loaded &&
                    displayingPage === config.DisplayingPage.view &&
                    <ContribuitorsViewer
                        showPage={this.showPage}
                        usedIds={this.state.usedIds}
                        appConfigs={appConfigs}
                        logout={this.props.logout}
                        currentOperator={currentOperator}
                        editContributer={this.state.editContributer}
                    />}
                {loadingState === config.LoadingStates.loaded &&
                    displayingPage === config.DisplayingPage.addEdit &&
                    <ContribuitorsManager
                        showPage={this.showPage}
                        usedIds={this.state.usedIds}
                        editContributer={this.state.editContributer}
                        logout={this.props.logout}
                        currentOperator={currentOperator}
                    />}
                {loadingState === config.LoadingStates.loaded &&
                    displayingPage === config.DisplayingPage.situations &&
                    <ContribuitorsSituations
                        showPage={this.showPage}
                        logout={this.props.logout}
                        currentOperator={currentOperator}
                    />}
                {loadingState === config.LoadingStates.loaded &&
                    displayingPage === config.DisplayingPage.list &&
                    <ContribuitorsLists
                        showPage={this.showPage}
                        logout={this.props.logout}
                        currentOperator={currentOperator}
                    />}
                {loadingState === config.LoadingStates.loaded &&
                    displayingPage === config.DisplayingPage.operator &&
                    <MainOperators
                        showPage={this.showPage}
                        logout={this.props.logout}
                        currentOperator={currentOperator}
                    />}
            </div>
        );
    };
}